import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "./shared/Loader";
import { dataLayerPush } from "../utils/gtm";
import { getIsRakshaPrime } from "../reducers/beneficiaries.slice";
import { useAppSelector } from "../app/hooks";

const ClaimDetailsComponent = () => {
  const navigate = useNavigate();
  const { param } = useParams();
  const isRakshaPrime = useAppSelector(getIsRakshaPrime);
  useEffect(() => {
    initializeClaimDetail();
  }, []);

  useEffect(() => {
    const component: any = document.querySelector(
      "claim-details-web-component"
    );

    if (component) {
      component.addEventListener("gtm-events", (e: any) => {
        e?.detail && dataLayerPush?.(e?.detail[0], e?.detail[1]);
      });
    }
  }, []);

  const initializeClaimDetail = () => {
    const eventQuerry = document.querySelector("claim-details-web-component");
    eventQuerry?.addEventListener("GO_BACK", (e: any) => {
      navigate(-1);
    });
    eventQuerry?.addEventListener("GO_HOME", (e: any) => {
      navigate("/");
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      {param && (
        <claim-details-web-component
          claimId={encodeURIComponent(param)}
          isRakshaPrime={isRakshaPrime}
        ></claim-details-web-component>
      )}
    </div>
  );
};

export default ClaimDetailsComponent;
