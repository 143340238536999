import React from 'react'
import rakshaBannerDesktop from '../../assets/images/rakshaPrime/CTA-raksha-prime.png'
import rakshaBannerMobile from '../../assets/images/rakshaPrime/CTA-raksha-prime-mobile.png'

const CTARakshaBanner = (props:any) => {
  return (
    <React.Fragment>
      <a href='https://rakshaprime.mediassist.in/' target="_blank" className={`d-inline-block w-full ${props.className}`}>
        <img src={rakshaBannerDesktop} alt="try-raksha-prime" className='d-none md-d-inline-block w-full' />
        <img src={rakshaBannerMobile} alt="try-raksha-prime" className='md-d-none w-full' />
      </a>
    </React.Fragment>  
  )
}

export default CTARakshaBanner