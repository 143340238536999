class Constants {
    private static _instance: Constants = new Constants();
    constructor() {
      if (Constants._instance) throw new Error('Use Constants.Instance');
      Constants._instance = this;
    }
  
    static get Instance(): any {
      return Constants._instance;
    }
  
    readonly fileRegExp: any = /^.*\.(jpg|JPG|png|PNG|pdf|PDF|jpeg|JPEG)$/;
    readonly denailClaimStatus : any[] = [11, 19, 23, 24];
    readonly approvedClaimStatus : any[] = [6, 58];
    readonly paidClaimStatus : any[] = [7, 22, 35];
    readonly informationAwaited : any[] = [28, 29, 31, 27, 30, 9, 18, 59, 60, 61, 62, 63, 64, 65, 66];
    readonly scrollTopPosition: number = 190;
    readonly allMonths = ['Jan','Feb','Mar', 'Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    readonly claimListFilters = ['Dependent', 'ClaimType', 'PolicyPeriod'];
    readonly healthBenefitsState = ['List', 'Benefit', 'Partner', "T&C", "TCSLocations"];
    readonly errorMessage = "Something went wrong"
    readonly locationTCS = [
      {name: "Bhubaneswar", vendor: "medibuddy"},
      {name:"Guwahati", vendor: "medibuddy"},
      {name:"Jamshedpur", vendor: "medibuddy"},
      {name:"Kolkata", vendor: "medibuddy"},
      {name:"Patna", vendor: "medibuddy"},
      {name:"Trivandrum", vendor: "medibuddy"},
      {name:"Raipur", vendor: "medibuddy"},
      {name:"Bangalore", vendor: "medibuddy"},
      {name:"Chennai", vendor: "medibuddy"},
      {name:"Coimbatore", vendor: "medibuddy"},
      {name:"Nagpur", vendor: "medibuddy"},
      {name: "Ahmedabad", vendor: "TATA1mg"},
      {name: "Bhopal", vendor: "TATA1mg"},
      {name: "Goa", vendor: "TATA1mg"},
      {name: "Hyderabad", vendor: "TATA1mg"},
      {name: "Indore", vendor: "TATA1mg"},
      {name: "Kochi", vendor: "TATA1mg"},
      {name: "Lucknow", vendor: "TATA1mg"},
      {name: "Mumbai", vendor: "TATA1mg"},
      {name: "New Delhi", vendor: "TATA1mg"},
      {name: "Nashik", vendor: "TATA1mg"},
      {name: "Pune", vendor: "TATA1mg"},
      {name: "Vardodara", vendor: "TATA1mg"},
      {name: "Varanasi", vendor: "TATA1mg"}
      ];
    readonly pol_categoryID  = [1,2,27,67];
    readonly APP_STORE_URL="https://apps.apple.com/us/app/maven-borderless-benefits/id1658508539?action=write-review"
    readonly PLAY_STORE_URL="market://details?id=in.mediassist.malite";
    readonly ACCOUNT_PAGE_CTA_INTERACTION = "account_page_cta_interaction";
    readonly BANK_ACCOUNT_CTA_INTERACTION = "bank_account_cta_interaction";
    readonly CLAIMS_POLICIES_CARD_INTERACTION = "claims_policies_card_interaction";
    readonly FILTER_CTA_INTERACTION = "filter_cta_interaction";
    readonly CLAIMS_POLICIES_CTA_INTERACTION = "claims_policies_cta_interaction";
    readonly EDIT_CONTACT_DETAIL_CTA_CLICK = "edit_contact_detail_cta_click";
    readonly MAIN_HOME_PAGE_CTA_INTERACTION = "main_home_page_cta_interaction";
    readonly BANNER_INTERACTION = "banner_interaction";
    readonly LOGIN_FORM_FILL = "login_form_fill";
    readonly LOGIN_FORM_CTA_INTERACTION = "login_form_cta_interaction";
    readonly LOGIN_SUCCESS = "login_success";
    readonly MULTIPLE_ACCOUNTS_FORM_FILL = "multiple_accounts_form_fill";
    readonly MULTIPLE_ACCOUNTS_CTA_INTERACTION = "multiple_accounts_cta_interaction";
    readonly NETWORK_HOSPITAL_CTA_INTERACTION = "network_hospital_cta_interaction";
    readonly HOSPITAL_SEARCH = "hospital_search";
    readonly VIEW_POLICY_DOCUMENTS_CTA_CLICK = "view_policy_documents_cta_click";
    readonly RESET_PASSWORD_CTA_INTERACTION = "reset_password_cta_interaction";
    readonly GENERIC_PAGE_CTA_INTERACTION = "generic_page_cta_interaction";
    readonly VERIFY_ACCOUNT_CTA_CLICK = "verify_account_cta_click";
    readonly SI_PROTECT_CTA_INTERACTION = "si_protect_cta_interaction";
    readonly SEND_OTP_CTA_CLICK = "send_otp_cta_click";
    readonly DOWNLOAD_ECARD_CTA_INTERACTION = "download_Ecard_cta_interaction";
    readonly TOP_NAVIGATION_INTERACTION = "top_navigation_interaction";
    readonly SELECT_INSURED_MEMBER = "select_insured_member";
    readonly SUBMISSIONS_IPD_CTA_INTERACTION   = "submissions_ipd_cta_interaction";
    readonly CLAIM_SUBMISSION_CTA_INTERACTION = "claim_submission_cta_interaction";
    readonly POLICY_FORM_FILL = "policy_form_fill";
    readonly POLICY_FORM_CTA_INTERACTION = "policy_form_cta_interaction";
    readonly POLICY_POPUP_CTA_INTERACTION = "policy_popup_cta_interaction";
    readonly MAIN_HOME_PAGE = "Main Home Page";
    readonly TOP_NAVIGATION_BAR = "Top Navigation Bar";
    readonly MEDIBUDDY="medibuddy";
    readonly CORPORATE_NAME = "corporate_name";
    readonly BOTTOM_NAVIGATION_BAR = "Bottom Navigation Bar";
    readonly CLAIM_INTIMATION_INITIATE = "claim_intimation_initiate";
    readonly BOTTOM_NAVIGATION_INTERACTION = "bottom_navigation_interaction";
    readonly MAVEN_WEB = "MAVEN|Web";
    readonly loginValueMobileConts={
      mobile: "",
      isValid: false,
    };
    readonly loginValueEmailConts={
      mobile: "",
      isValid: false,
    }
  }
  export default Constants;