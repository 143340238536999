import React, { useEffect, useState } from "react";
import { Header } from "@mediassistrepo/maven-old";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { baseProfileAsync, getBaseProfile } from "../../reducers/baseProfile.slice";
import { useLocation, useNavigate } from "react-router";
import { getCookieValue, postToNativeApp } from "../../common/helpers";
import { getClaimList, getFreshRequestClaimList } from "../../reducers/claimList.slice";
import { getConfig } from "../../reducers/config.slice";
import useWindowWidth from "../../hooks/getWindowWidth";
import { getActiveClaims } from "../../reducers/activeClaims.slice";
import { getActiveBeneficiaries, getBeneficiaries, getIsRakshaPrime } from "../../reducers/beneficiaries.slice";
import { getflexDetails } from "../../reducers/flexDetails.slice";
import { dataLayerPush } from "../../utils/gtm";
import Constants from "../../constants/Constants";
import { getMultiUserData, getMultiUserDetails, multiUserDataAsync, multiUserDetailsAsync } from "../../reducers/multiUserDetails.slice";
import { multiUserLogin, multiUserLoginAccessToken } from "../../api/loginAPI";
import { saveAccessToken } from "../../reducers/login.slice";
import { getAccessToken } from "../../utils";

const HeaderWeb = (props: any) => {
  const location = useLocation();
  const accessToken = getAccessToken();
  const multiUserDetails = useAppSelector(getMultiUserDetails);

  const {
    show = true,
    isShowBackButton = false,
    oldHeader = false,
    name = "",
    onBackBtnClick = null,
    handleEcard,
    policyDuration,
    policyNumber,
    linkPolicyHeader = false,
    isShowNotification = false,
    isNewNotification = false,
    isMultiUserWalkThrough = false,
    showProfileOptions = false
  } = props;

  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile);
  const activeBenefs = useAppSelector(getActiveBeneficiaries);
  const beneficiaryDetails = useAppSelector(getBeneficiaries);
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState([
    {
      id: 0,
      name: "",
      status: "",
      polHolderName:"",
      // policyDuration:"",
      // policyNumber:""
    },
  ]);

  const corporateConfig = useAppSelector(getConfig);
  const activeClaimsResponse = useAppSelector(getActiveClaims);
  const freshClaimListCount = useAppSelector(getFreshRequestClaimList);
  const flexDetails = useAppSelector(getflexDetails);
  const [polHolderName, setPolHolderName] = useState<any>("");
  const isRakshaPrime = useAppSelector(getIsRakshaPrime);

useEffect(() => {
    if(!multiUserDetails){
      dispatch(multiUserDetailsAsync());
    }
    if(!baseProfile?.data){
      dispatch(baseProfileAsync());
    }
  }, []);

  useEffect(() => {
    const pribenef = beneficiaryDetails?.beneficiaryDetails.filter((x:any)=>x.relationToPrimaryId === 1);
     if(pribenef && pribenef.length > 0 && pribenef[0].polCorporateId){
      setPolHolderName(beneficiaryDetails?.policyDetails[0]?.polHolderName)
     };
    const data= baseProfile?.data?.userName;
    if(multiUserDetails?.users?.length>1 && data){
        const user = multiUserDetails?.users?.map((value :any, i:number) => ({
        id: i,
        name: value?.username,
        status: "",
        polHolderName: value?.username === data ? beneficiaryDetails?.policyDetails[0]?.polHolderName : "",
        // policyDuration: value?.username === data? policyDuration : "",
        // policyNumber: value?.username === data? policyNumber : "",
        }));
      setOptions([...user]);
      return;
    }
    if (baseProfile?.data) {
      setOptions([
        {
          id: 0,
          name: baseProfile.data.firstName,
          status: "",
          polHolderName:beneficiaryDetails?.policyDetails[0]?.polHolderName
          // policyDuration:policyDuration,
          // policyNumber:policyNumber
        }
      ]);
    }
  }, [baseProfile,multiUserDetails,beneficiaryDetails]);

  
  const handleMultiUserRequest = async(request: any) => {    
    const response = await multiUserLogin(request);   
    return response.data
  }

  const handleMultipleUserProceed = async(req: any) => {
    const response = await multiUserLoginAccessToken(req);
    saveAccessToken(response?.data)
    return response?.data
  }

  
  return (
    <div className="sticky-div">
      <Header
      className={props?.className}
      isRakshaPrime={isRakshaPrime}
      rakshaPrimeClick={()=>navigate("/raksha-prime")}
      isMultiUser={props?.isMultiUser}
      isWalkthroughTint={props?.isWalkthroughTint}
      toggleHeader={props?.toggleHeader}
      headerActive={props?.headerActive}
      footerActive={props?.footerActive}
      dataLayerPush={dataLayerPush}
        showSubmitClaim={
          useWindowWidth() > 1023 ? props?.showSubmitClaim : false
        }
        openProfileOptions={showProfileOptions}
        linkPolicyHeader={linkPolicyHeader}
        isShowNotification={isShowNotification}
        isNewNotification={isNewNotification} 
        corporateConfig={corporateConfig}
        policyDuration={policyDuration}
        policyNumber={policyNumber}
        style={{ width: "100%", display: "flex" }}
        hiddenMb={!show}
        oldHeader={oldHeader}
        isShowBackBtn={isShowBackButton}
        name={name}
        subtitle={""}
        onBackBtnClick={() => {
          onBackBtnClick ? onBackBtnClick() : navigate(-1);
        }}
        options={options}
        onLogoutClick={() => {
          dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
            header: "Profile",
            sub_header:"Logout"
          });
          navigate("/logout");
        }}
        onLinkAccountClick={() => {
          dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
            header: "Profile",
            sub_header:"Link Account"
          });
          navigate("/link-policy")}}
        claimsCount={
          freshClaimListCount || 0
        }
        actions={{
          onNotification: () => {
            navigate("/notifications");
          },
          onSubmitClaimClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Submit Claim"
            });
            // navigate("/claimSubmission/IPD");
          },
          onTrackClaim: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Track Claim"
            });
            navigate("/claim-list");
          },
          onSearchNetworkHospital: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Search Network Hospital"
            });
            navigate("/hospitals");
          },
          onDownloadECard: () => {
            props?.setSectionName?.(Constants.Instance.TOP_NAVIGATION_BAR)
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Download E-card"
            });
            handleEcard();
          },
          onRaiseQueryClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Need help?"
            });
            navigate("/help");
          },
          onAddMemberClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Add Member"
            });
            navigate("/enrollment");
          },
          onSubmitIntimation: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Submit Intimation"
            });
            navigate("/intimation");
            dataLayerPush(Constants.Instance.CLAIM_INTIMATION_INITIATE, { 
              cta_text : "Submit intimation", 
              section_name : "Top Navigation Bar", 
              header : "Actions"
            });
          },
          knowYourCoverage: () => {
            navigate("/intimation?oop=true");
          },

          handleClickClinic: () => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Clinic/OPD"
            });
            navigate("/claimSubmission/OPD");
            navigate(0);
          },
          handleClickWellness: () => {
            navigate("");
          },
        }}
        handleClickHospital={() => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Hospitalisation"
          });
          navigate("/claimSubmission/IPD");
          navigate(0);
        }}
        navigations={{
          onHomeClick: () => {
            navigate("/");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Home",
              sub_header:"NA"
            });
          },
          onClaimsClick: () => {
            navigate("/claim-list");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Claims",
              sub_header:"NA"
            });
          },
          onPolicyClick: () => {
            navigate("/policy-list");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Policy List",
              sub_header:"NA"
            });
          },
          onProfileClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Account",
              sub_header:"NA"
            });
            if (location?.pathname?.includes("/account")) {
              navigate(0);
            } else {
              navigate("/account");
            }
          },
        }}
        activeName={baseProfile?.data?.firstName}
        token={multiUserDetails?.encryptedToken}
        onClose={props.onClose}
        hideEcardDownload={!activeBenefs?.length || corporateConfig?.hideEcard}
        flexDetails={flexDetails}
        handleMultiUserRequest={handleMultiUserRequest}
        loginValue={baseProfile?.data?.mobileNo}
        handleLoginRedirect={() => {
          navigate("/")
          window.location.reload();
        }}
        handleMultipleUserProceed={handleMultipleUserProceed}
        isMultiUserWalkThrough={isMultiUserWalkThrough}
        multiUserData={multiUserDetails}
      />
    </div>
  );
};

export default HeaderWeb;
