import React, { useEffect, useState } from "react";
import {
  UpdateContact,
  UpdateContactModel,
} from "@mediassistrepo/homepage-component";
import { useNavigate } from "react-router";
import { getBeneficiaries } from "../../reducers/beneficiaries.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { baseProfileAsync, getBaseProfile, isBaseProfileLoading } from "../../reducers/baseProfile.slice";
import { isConfigLoading } from "../../reducers/config.slice";
import { getConfig } from "@testing-library/react";
import { postToNativeApp } from "../../common/helpers";

const UpdateContactStrip = (props:any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const benefResponse = useAppSelector(getBeneficiaries);
  const [isvisible, SetIsVisible] = useState<boolean>(false);
  const [loginValue, setLoginValue] = useState<string>("");
  const [loginValueMobile, setLoginValueMobile] = useState<any>({
    mobile: "",
    isValid: false,
  });
  const [loginValueEmail, setLoginValueEmail] = useState<any>({
    email: "",
    isValid: false,
  });
  const baseProfileLoading = useAppSelector(isBaseProfileLoading);
  const baseProfile = useAppSelector(getBaseProfile)
  const configLoading = useAppSelector(isConfigLoading);
  const corporateConfig:any = useAppSelector(getConfig);


  useEffect(() => {
    if(!baseProfile){
      dispatch(baseProfileAsync());
    }

    setLoginValue(localStorage.getItem("loginValue"));
  }, []);

  
  useEffect(() => {
    
    postToNativeApp({ hideFooter: isvisible });
  }, [isvisible]);
  
  useEffect(()=>{
    
    if (!baseProfileLoading && !configLoading && baseProfile?.data && corporateConfig) {
      postToNativeApp({ "config": corporateConfig })
  
      const { userId, emailId, mobileNo, isEmailVerified, isMobileVerified } = baseProfile?.data;
      const { isSSOUser } = corporateConfig;
      
      if (!localStorage.getItem('notVerified')) {
        const isLinkPolicy = localStorage.getItem("isLinkPolicy");
        

          if (
            !emailId ||
            !mobileNo ||
            isLinkPolicy ||
            (!isSSOUser && (!isMobileVerified || !isEmailVerified))
          ) {
            SetIsVisible(true);
        }
      }
    }
    SetIsVisible(props?.renderComp);
  },[corporateConfig, baseProfile,baseProfileLoading,props?.renderComp])


  useEffect(() => {
    var re = /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/;
    const isValid = re.test(loginValue);
    if (isValid) {
      setLoginValueMobile({ mobile: loginValue, isValid: true });
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const trimmedInput = loginValue?.trim();
    const isValidEmail = emailPattern.test(trimmedInput);
    if (isValidEmail) {
      setLoginValueEmail({ email: loginValue, isValid: true });
    }
  }, [loginValue]);

  const handleEditContacts = async (benefResponse: any) => {
    const pribenef = benefResponse?.beneficiaryDetails?.filter(
      (x: any) => x?.relationToPrimaryId === 1
    );

      navigate(
        `/updateContact`,
        { state: { familyData: pribenef } }
      );
  };

  const handleSkip = () => {
    SetIsVisible(false);
    localStorage.setItem("notVerified", "true");
    localStorage.setItem("isLinkPolicy", "false");
    // navigate("/");
  };

  return (
    <div style={{ paddingTop: "0 20px" }}>
      <div>
        <UpdateContact setIsVisiblsModel={SetIsVisible} />
      </div>
        <UpdateContactModel
          isVisibleModel={isvisible}
          setIsVisiblsModel={handleSkip}
          isPrimaryMobile={loginValueMobile?.isValid}
          primaryNo={loginValueMobile?.mobile}
          isPrimaryEmail={loginValueEmail?.isValid}
          primaryEmail={loginValueEmail?.email}
          handleProceed={() => {
            handleEditContacts(benefResponse);
          }}
        />
    </div>
  );
};

export default UpdateContactStrip;
