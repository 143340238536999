import { getRequest, postRequest } from "../app/postRequest.service";
import { BaseResponse } from "../interfaces/claimList.interface";
import { DecryptedDataResponse, EncryptedClaimIdResponse, EncryptedDataResponse, IntimationCheckRequest } from "../interfaces/master.interface";

export function encryptClaimId(claimId: string) {
  return new Promise<{ data: EncryptedClaimIdResponse }>((resolve) =>
    resolve(getRequest(`/master/claimId/encrypt?claimId=${claimId}`))
  );
}

export function checkIntimationId(req: IntimationCheckRequest) {
  return new Promise<{ data: BaseResponse }>((resolve) =>
    resolve(postRequest(`/master/intimationExist`,req))
  );
}