import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { beneficiariesApi } from '../api/homeAPI';
import { BeneficiariesResponse, BeneficiaryDetail, Insurer } from '../interfaces/beneficiaries.interface';
import { setMaid } from '../utils';

export interface BeneficiariesState {
  value: BeneficiariesResponse | null;
  status: 'idle' | 'loading' | 'failed';
  activeBenefs: BeneficiaryDetail[];
  allBenefs: BeneficiaryDetail[];
  insurerList: Array<Insurer>;
  isRakshaPrime:boolean;
}

const initialState: BeneficiariesState = {
  value: null,
  status: 'idle',
  activeBenefs: null,
  allBenefs: null,
  insurerList: null,
  isRakshaPrime:false
};

export const beneficiariesAsync = createAsyncThunk(
  'beneficiaries/beneficiariesAsync',
  async () => {
    const response = await beneficiariesApi()
    return response.data;
  }
);

export const beneficiariesSlice = createSlice({
  name: 'beneficiaries',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
    setActiveBeneficiaries: (state, action) => {
      state.activeBenefs = action.payload;
    },
    setAllBenefs: (state, action) => {
      state.allBenefs = action.payload;
    },
    setInsurerList: (state,action) => {
      state.insurerList = action.payload;
    },
    setIsRakshaPrime:(state,action)=>{
      state.isRakshaPrime = action.payload;
    }
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(beneficiariesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(beneficiariesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        
        if(action.payload?.beneficiaryDetails){
          localStorage.setItem('dbType', action.payload?.beneficiaryDetails[0]?.sourceDBType);
          setMaid(action.payload?.beneficiaryDetails[0]?.mediAssistId.toString() || "")
        }else{
          action.payload.beneficiaryDetails = [];
        }
        state.value = action.payload;
      })
      .addCase(beneficiariesAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setActiveBeneficiaries,setAllBenefs, setInsurerList, setIsRakshaPrime} = beneficiariesSlice.actions;
export const getBeneficiaries = (state: RootState) => state.beneficiaries.value;
export const getBeneficiariesLoading = (state: RootState) => state.beneficiaries.status === 'loading';
export const getBeneficiariesStatus = (state : RootState) => state.beneficiaries.status === 'idle'  || state.beneficiaries.status === 'failed';
export const getActiveBeneficiaries = (state: RootState) => state.beneficiaries.activeBenefs;
export const getAllBeneficiaries = (state: RootState) => state.beneficiaries.allBenefs;
export const getInsurerList = (state: RootState) => state.beneficiaries.insurerList;
export const getIsRakshaPrime = (state:RootState) => state.beneficiaries.isRakshaPrime;

export default beneficiariesSlice.reducer;
