import { Help } from "@mediassistrepo/maven-old";
import { useAppSelector } from "../app/hooks";
import { getConfig, isConfigLoading } from "../reducers/config.slice";
import Loader from "./shared/Loader";
import { dataLayerPush } from '../utils/gtm';
import { useEffect, useRef } from "react";
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { ConversationPropsEnum, FreshChat, FreshchatConversationProps, FreshchatInitProps, FreshchatUserProps, UserPropsEnum } from "../interfaces/config.interface";
import {  BaseProfileResponse } from "../interfaces/baseProfile.interface";
import { getBeneficiaries } from "../reducers/beneficiaries.slice";
import { BeneficiariesResponse } from "../interfaces/beneficiaries.interface";

declare global {
  interface Window {
    fcWidget:any;
  }
}

const HelpSection = () => {
    const corporateConfig = useAppSelector(getConfig);
    const configLoading = useAppSelector(isConfigLoading);
    const baseProfile = useAppSelector(getBaseProfile);
    const benefResponse = useAppSelector(getBeneficiaries);
    const fcWidgetRef = useRef(null);

    useEffect(() => {
       if(corporateConfig?.freshchat &&  baseProfile?.data && benefResponse){
           const initializationProps = getInitProps(corporateConfig.freshchat);
           const userProps = getUserProps(corporateConfig.freshchat,baseProfile);
           const convProps = getConversationProps(corporateConfig.freshchat,benefResponse);
           if(fcWidgetRef && fcWidgetRef.current){
            fcWidgetRef.current?.init(initializationProps);
            fcWidgetRef.current?.user?.clear();
            fcWidgetRef.current?.user?.setProperties(userProps);
            fcWidgetRef.current?.conversation?.setProperties(convProps);
           }else{
            fcWidgetRef.current  = window.fcWidget;
            fcWidgetRef.current?.init(initializationProps);
            fcWidgetRef.current?.user?.clear();
            fcWidgetRef.current?.user?.setProperties(userProps);
            fcWidgetRef.current?.conversation?.setProperties(convProps);
           }

       }

    },[corporateConfig,baseProfile,benefResponse]);

    useEffect(() => {
     return () => {
      fcWidgetRef?.current?.destroy();
     }
    },[]);

    const openFreshChatBot = () => {
      fcWidgetRef?.current?.open();
    }

    const getInitProps = (freshchatConfig: FreshChat) => {
          const initProps = new FreshchatInitProps();
          initProps.host = `${process.env.REACT_APP_FRESHCHAT_HOST}`;
          initProps.token = `${process.env.REACT_APP_FRESHCHAT_TOKEN}`;
          if(freshchatConfig?.widgetUuid){
            initProps.widgetUuid = freshchatConfig.widgetUuid;
          }
          if(freshchatConfig?.tags){
            initProps.tags = freshchatConfig.tags;
          }
          return initProps;
    }

    const getUserProps = (freshchatConfig: FreshChat,baseProfile: BaseProfileResponse) => {
      const userProps = new FreshchatUserProps();
      if(freshchatConfig?.userProperties?.length && baseProfile?.data){
        freshchatConfig.userProperties.forEach((userProp) => {
            if(userProp == UserPropsEnum.EMAIL){
              userProps.email = baseProfile?.data?.emailId;
            }else if(userProp == UserPropsEnum.FIRST_NAME){
                userProps.firstName = baseProfile?.data?.firstName;
            }else if(userProp == UserPropsEnum.CF_GROUPFORTEST){
              userProps.cf_groupfortest = freshchatConfig?.groupId;
            }
        });
      }
      return userProps;
    }

    const getConversationProps = (freshchatConfig: FreshChat, benefRespone : BeneficiariesResponse) => {
      const conversationProps = new FreshchatConversationProps();
      if(freshchatConfig?.conversationProperties?.length){
        freshchatConfig.conversationProperties.forEach((conversationProp) => {
            if(conversationProp == ConversationPropsEnum.CORPORATE){
              conversationProps.corporate = (benefRespone?.policyDetails?.length >0 ) ?  benefRespone?.policyDetails[0].polHolderName: '';
            }else if(conversationProp == ConversationPropsEnum.FOLDER){
                conversationProps.folder = freshchatConfig?.folder;
            }
        });
      }
      return conversationProps;
    }

  return (
    <>
    {configLoading && <Loader />}
    {!configLoading && <Help configData={corporateConfig} showFreshChatButton={corporateConfig?.freshchat?.showFreshChatButton} freshChatButtonText={corporateConfig?.freshchat?.freshChatButtonText}  openFreshChatBot={openFreshChatBot} dataLayerPush={dataLayerPush}/>}
    </>

  )
}

export default HelpSection