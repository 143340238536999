import React, { useEffect } from "react";
import { RakshaPrimePage } from "@mediassistrepo/homepage-component";
import HeaderWeb from "./shared/Header";
import { useAppSelector } from "../app/hooks";
import { getIsRakshaPrime } from "../reducers/beneficiaries.slice";
import NotFound from "./NotFound";

const RakshaPrime = () => {
  const isRakshaPrime = useAppSelector(getIsRakshaPrime);
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <>
      {width <= 768 && isRakshaPrime ? (
        <div>
          <HeaderWeb className="prime-header" oldHeader={true} isShowBackButton={true} name="Raksha prime" />
          <RakshaPrimePage />
        </div>
      ) : <NotFound />
      }
    </>
  );
};

export default RakshaPrime;