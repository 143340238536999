import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getCookieValue, postToNativeApp } from "../common/helpers";
import { logout } from "../reducers/login.slice";
import { resetToInitial } from "../reducers/claimList.slice";
import { resetToInitialConfig } from "../reducers/config.slice";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

    useEffect(() => {
      for (let key in localStorage) {
        if (localStorage.hasOwnProperty(key) && (key !== 'EnableBiometric' && key !== 'biometricType')) {
          localStorage.removeItem(key);
        }
      }
        sessionStorage.clear();
        
        dispatch(logout());
        dispatch(resetToInitial());
        dispatch(resetToInitialConfig());
        if (getCookieValue("source") === "native") {
            postToNativeApp({
              logout: "logout",
            });
          }
        navigate("/")
    })
    return(<></>)
}
export default Logout;